import { IWC_Cart_Fragments_Params } from "./classes/cart-fragments/interface.cart-fragments-handler-settings";
import { Frontend_Scripts_Manager } from "./classes/scripts-manager/class.frontend-scripts-manager";
import { IAelia_CS_Frontend_Params } from "./classes/scripts-manager/interface.aelia-currency-switcher-params";

// Add a reference to the global parameters
declare global {
	var wc_aelia_currency_switcher_params: IAelia_CS_Frontend_Params;
	var wc_cart_fragments_params: IWC_Cart_Fragments_Params;
}

(function ready(callback, args) {
  if(document.readyState != 'loading'){
		// If this function is called after the document has finished loading,
		// invoke the callback immediately
		callback(args);
	}
	else {
		// If the document is still loading, trigger the callback on the
		// "DOM loaded" event
		document.addEventListener('DOMContentLoaded', () => callback(args));
  }
})(Frontend_Scripts_Manager.init, wc_aelia_currency_switcher_params);

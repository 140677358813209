import { Widget } from "./class.widget";

/**
 * Implements the features for the advanced dropdown currency selector widget, which
 * is rendered with HTML elements instead of a basic <select> field.
 *
 * @since 4.12.2.210706
 */
export abstract class Advanced_Dropdown_Widget extends Widget { // NOSONAR
	/**
	 * The argument passed with POST requests to select a currency.
	 */
	protected ARG_CURRENCY = 'aelia_cs_currency';

	/**
	 * Initialise the event handlers used by the widget.
	 */
	protected init_event_handlers(): void {
		// @since 4.12.4.210805
		super.init_event_handlers();

		// Close all dropdowns when the user clicks anywhere on the document other than a country selector
		jQuery(document).on('click', (event: Event): void => { // NOSONAR
			// If the target is not the country selector, close all the dropdowns
			if(jQuery(event.target).closest('.dropdown_selector .dropdown, .dropdown_selector .selected_option, .dropdown_selector .search').length <= 0) {
				this.close_all_dropdowns();
			}
		});

		// Close all dropdowns when the user clicks anywhere on the document other than a country selector
		jQuery(document).on('keyup', '.widget_wc_aelia_currencyswitcher_widget .search', (event: JQuery.TriggeredEvent): void => { // NOSONAR
			switch(event.which ?? '') {
				// When the user presses Return on the search field, submit the form using the first selected country
				case 13:
					const $selected_option: JQuery = jQuery(event.currentTarget).parents('.dropdown').first().find('.option.selected');
					if($selected_option) {
						this.submit_selection($selected_option.data('value'));
					}

					break;
				// In all other cases, filter the list of elements in the dropdown
				default:
					const $dropdown = jQuery(event.currentTarget).parents('.dropdown').first();
					const search_value = jQuery(event.currentTarget).val().toString();

					this.filter_options_by_search_value($dropdown, search_value);
			}
		});
	}

	/**
	 * Returns all the dropdowns handled by a specific widget. Descendant classes must
	 * implement this method to return a jQuery object that matches all the dropdowns
	 * they handle (e.g. all the currency selectors, all the country selectors, etc)
	 *
	 * @return JQuery
	 * @since 4.12.2.210706
	 */
	protected abstract get_all_dropdowns(): JQuery;

	/**
	 * Opens or closes a dropdown when a currency selector is clicked.
	 *
	 * @param event
	 * @param $widget
	 */
	protected toggle_dropdown(event: Event, $widget: JQuery): void {
		const $dropdown: JQuery = $widget.find('.dropdown');
		if($dropdown.is(':visible')) {
			$widget.removeClass('active');
			$dropdown.slideUp(300);
		}
		else {
			this.close_all_dropdowns();
			this.set_dropdown_position($dropdown);

			// Open the dropdown with a sliding animation
			$dropdown.slideDown(300, (): void => {
				// Set the focus on the search field, if present
				$dropdown.find('.search').trigger('focus');
			});

			// Tag the dropdown as "active"
			$widget.addClass('active');
		}
	}

	/**
	 * Closes all the currency selector dropdowns open on the page.
	 *
	 * @since 4.12.1.210629
	 */
	protected close_all_dropdowns(): void {
		const $dropdowns = this.get_all_dropdowns();
		// Close the dropdowns
		$dropdowns.slideUp(300).removeClass('active');

		// Reset any filter that was applied to the dropdowns
		$dropdowns.each((index, element) => {
			// @since 4.12.2.210706
			this.reset_filtered_options(jQuery(element));
		});
	}

	/**
	 * Positions the dropdwon with the currency list relative to the currency field.
	 *
	 * @param JQuery $dropdown
	 * @since 4.12.1.210629
	 */
	protected set_dropdown_position($dropdown: JQuery): void {
		$dropdown.css('top', 'calc(100%)');
		$dropdown.css('bottom', '');
		$dropdown.css('display', 'block');

		const dropdown_data = $dropdown[0].getBoundingClientRect();
		$dropdown.css('display', 'none');
		if(window.innerHeight < dropdown_data.y + dropdown_data.height) {
			$dropdown.css('top', 'auto');
			$dropdown.css('bottom', 'calc(100%)');
		}
	}

	/**
	 * Intercepts the selection and performs a POST request to update the page.
	 *
	 * @param {string} selected_value
	 * @returns void
	 */
	protected abstract submit_selection(selected_value: string): void;

	/**
	 * Removes all the filters set against the options in the dropdown, showing all of them.
	 *
	 * @param $dropdown
	 * @since 4.12.2.210706
	 */
	protected reset_filtered_options($dropdown: JQuery): void {
		$dropdown.find('.search').val('');
		$dropdown.find('.option').removeClass('filter_hidden').removeClass('selected');
	}

	/**
	 * Filters the list of options in a dropdown, keeping only the ones whose search data
	 * matches the search value. The search is case-insensitive.
	 *
	 * @param $dropdown
	 * @param search_value
	 */
	protected filter_options_by_search_value($dropdown: JQuery, search_value: string): void {
		// Set the search value to lower-case, for a case-insensitive search
		const _search_value = search_value.trim().toLowerCase();
		if(_search_value) {
			// Clear any selected option
			$dropdown.find('.option').removeClass('selected');

			// Filter the options, keeping only the ones that match the search value
			$dropdown.find('.option').each((index, element) => {
				const $current_element = jQuery(element);
				const $search_data: string[] = $current_element.data('search_data');

				console.log($search_data);
				let search_match = false;
				for(let idx in $search_data) {
					// If the search value matches the search data of the current element, stop here
					if($search_data[idx].toString().toLowerCase().includes(_search_value)) {
						search_match = true;
						break;
					}
				}

				$current_element.toggleClass('filter_hidden', !search_match);
			});
			$dropdown.find('.option:visible').first().addClass('selected');
		}
		else {
			// If the search field is empty, show all options
			this.reset_filtered_options($dropdown);
		}
	}
}
import { Advanced_Dropdown_Widget } from "./base/class.advanced-dropdown-widget";

/**
 * Implements the features for the basic dropdown country selector widget.
 *
 * @since 4.12.1.210629
 */
export class Dropdown_Country_Selector_Widget extends Advanced_Dropdown_Widget { // NOSONAR
	/**
	 * The argument passed with POST requests to select a country.
	 */
	protected ARG_CUSTOMER_COUNTRY: string = 'aelia_customer_country';

	/**
	 * Returns a jQuery element matching all the selector widgets handled by this class.
	 *
	 * @returns JQuery
	 */
	protected get_all_dropdowns(): JQuery {
		return jQuery('.wc_aelia_cs_country_selector.active').find('.dropdown');
	}

	/**
	 * Initialise the event handlers used by the widget.
	 */
	protected init_event_handlers(): void {
		super.init_event_handlers();

		// @since 4.12.2.210706
		// Advanced dropdown selector widget (custom HTML, flags)
		// Activate the country selector dropdown when the selector is clicked
		jQuery(document).on('click', '.wc_aelia_cs_country_selector .selected_country', (event: Event): void => { // NOSONAR
			this.toggle_dropdown(event, jQuery(event.currentTarget).parents('.wc_aelia_cs_country_selector').first());
		});

		// Intercepts the country selection and reloads the page with the new country
		jQuery(document).on('click', '.wc_aelia_cs_country_selector .country', (event: Event): void => { // NOSONAR
			this.submit_selection(jQuery(event.currentTarget).data('value'));
		});

		// Basic dropdown selector widget
		// Monitor the "on change" at the document level, to intercept the country selection
		// in widgets created on the fly (e.g. by page builders)
		// @since 4.7.11.190924
		jQuery(document).on('change', '.currency_switcher.widget_wc_aelia_country_selector_widget .countries', (event: Event): void => { // NOSONAR
			event.stopPropagation();
			this.submit_selection(jQuery(event.currentTarget).val().toString());
		});
	}

	/**
	 * Intercepts the country selection and performs a POST request to update the page.
	 *
	 * @param selected_value
	 * @returns void
	 */
	protected submit_selection(selected_value: string): void {
		if(!selected_value) {
			return;
		}

		// Trigger an event to indicate that a country has been selected. This will purge the
		// cart fragments before submitting the selection, and ensure that the cart fragments
		// are fetched "fresh", using the new country
		jQuery(document).trigger('aelia_currency_selected');

		// Create an <input> element to contain the country
		const country_field: HTMLInputElement = document.createElement('input');
		country_field.type = 'hidden';
		country_field.name = this.ARG_CUSTOMER_COUNTRY;
		country_field.value = selected_value;

		// Create a hidden <form> element that will be used to send the POST request
		const form: HTMLFormElement = document.createElement('form');
		form.classList.add('country_switch_form');
		form.method = 'post';
		form.style.display = 'none !important';
		form.appendChild(country_field);
		document.body.appendChild(form);

		// Submit the form
		form.submit();
	}
}
import { IWidget_Settings } from "../interface.widget-settings";

/**
 * A base class for the widgets provided by the Aelia Currency Switcher.
 *
 * @since 4.12.1.210629
 */
export abstract class Widget { // NOSONAR
	/**
		* The settings passed to the class.
		*
		* @var IWidget_Settings
		*/
	protected settings: IWidget_Settings;

	/**
	 * Constructor.
	 *
	 * @param settings IWidget_Settings
	 */
	public constructor(settings: IWidget_Settings) {
		this.settings = settings;

		// Initialise the event handlers used by the widget
		this.init_event_handlers();
	}

	/**
	 * Initialise the event handlers used by the widget.
	 */
	protected init_event_handlers(): void {
		// Purge the cart fragments when the country or the currency selection form is submitted
		// @since 4.12.4.210805
		jQuery(document).on('submit', '.currency_switch_form, .country_switch_form', (event: Event): void => {
			this.purge_cart_fragments();
		});

		// Purge the cart fragments when the country or the currency change event is fired
		// @since 4.12.4.210805
		jQuery(document).on('aelia_currency_selected aelia_country_selected', (event: Event): void => {
			this.purge_cart_fragments();
		});
	}

	/**
	 * Purges the cart fragments. Convenience method.
	 */
	protected purge_cart_fragments(): void {
		this.settings.cart_fragments_handler.purge_cart_fragments();
	}
}
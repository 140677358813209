import { Cart_Fragments_Handler } from '../cart-fragments/class.cart-fragments-handler';
import { Widget } from '../widgets/base/class.widget';
import { IDictionary } from '../../../../../common/interface.dictionary';
import { IAelia_CS_Frontend_Params } from './interface.aelia-currency-switcher-params';
import { Dropdown_Currency_Selector_Widget } from '../widgets/class.dropdown-currency-selector-widget';
import { IWidget_Settings } from '../widgets/interface.widget-settings';
import { Dropdown_Country_Selector_Widget } from '../widgets/class.dropdown-country-selector-widget';

/**
 * Implements the scripts to handle WooCommerce's cart fragments.
 *
 * @since 4.12.1.210629
 */
export class Frontend_Scripts_Manager { // NOSONAR
	/**
	 * The singleton instance of this class.
	 */
	protected static _instance: Frontend_Scripts_Manager;

	 /**
		* The settings passed to the class.
		*/
	protected settings: IAelia_CS_Frontend_Params;

	/**
	 * A list of the widgets loaded on the frontend.
	 */
	protected widgets: IDictionary<Widget> = {};

	/**
	 * Constructor.
	 *
	 * @param settings IAelia_CS_Frontend_Params
	 */
	public constructor(settings: IAelia_CS_Frontend_Params) {
		this.settings = settings;

		// If the cart fragments variable is missing, use a default object,
		// with empty values. This condition can occur if some optimisation
		// plugin strips the cart fragment scripts from the frontend.
		// The scripts should NOT be removed, but some tools do it anyway
		// @since 4.12.4.210805
		const cart_fragments_params = window.wc_cart_fragments_params ?? {
			ajax_url: '',
			cart_hash_key: '',
			fragment_name: '',
			request_timeout: 0,
		}

		// Initialise the Cart Fragments Handler
		Cart_Fragments_Handler.init({
			// Variable wc_cart_fragments_params is global, generated by WooCommerce
			// @see IWC_Cart_Fragments_Params
			cart_fragments_params: cart_fragments_params,
		});

		const widgets_settings: IWidget_Settings = {
			cart_fragments_handler: Cart_Fragments_Handler.instance(),
		}

		this.widgets['currency-selector'] = new Dropdown_Currency_Selector_Widget(widgets_settings);
		this.widgets['country-selector'] = new Dropdown_Country_Selector_Widget(widgets_settings);
	}

	/**
	 * Returns the instance of this class.
	 *
	 * @returns Edit_Product
	 */
	public static instance(): Frontend_Scripts_Manager {
		// If the class has not been instantiated, throw an exception. The handler must be
		// initialised first, as it needs a couple of settings to get ready.
		if(!Frontend_Scripts_Manager._instance) {
			throw new Error('Class "Frontend_Scripts_Manager" has not been instantiated. Please call `Frontend_Scripts_Manager.init(<settings>)` before calling `Frontend_Scripts_Manager.instance()`.');
		}
		return Frontend_Scripts_Manager._instance;
	}

	/**
	 * Initialises the class and its event handlers.
	 *
	 * @param settings IAelia_CS_Frontend_Params
	 * @return Frontend_Scripts_Manager
	 */
	public static init(settings: IAelia_CS_Frontend_Params): Frontend_Scripts_Manager {
		// Initialise the Edit_Product handler. We can't call Edit_Product.instance here,
		// as the handler must be initialised with the settings first
		return new Frontend_Scripts_Manager(settings);
	}
}
import { ICart_Fragments_Handler_Settings } from './interface.cart-fragments-handler-settings';

/**
 * Implements the scripts to handle WooCommerce's cart fragments.
 *
 * @since 4.12.1.210629
 */
export class Cart_Fragments_Handler { // NOSONAR
	/**
	 * The argument that can be used to set a currency via the URL.
	 *
	 * @var string
	 */
	protected static ARG_CURRENCY: string = 'aelia_cs_currency';

	/**
	 * The singleton instance of this class.
	 *
	 * @var Cart_Fragments_Handler
	 */
	protected static _instance: Cart_Fragments_Handler;

	 /**
		* The settings passed to the class.
		*
		* @var ICart_Fragments_Handler_Settings
		*/
	protected settings: ICart_Fragments_Handler_Settings;

	/**
	 * Constructor.
	 *
	 * @param settings ICart_Fragments_Handler_Settings
	 */
	public constructor(settings: ICart_Fragments_Handler_Settings) {
		this.settings = settings;
		Cart_Fragments_Handler._instance = this;

		this.maybe_invalidate_minicart_cache();
	}

	/**
	 * Returns the instance of this class.
	 *
	 * @returns Edit_Product
	 */
	public static instance(): Cart_Fragments_Handler {
		// If the class has not been instantiated, throw an exception. The handler must be
		// initialised first, as it needs a couple of settings to get ready.
		if(!Cart_Fragments_Handler._instance) {
			throw new Error('Class "Cart_Fragments_Handler" has not been instantiated. Please call `Cart_Fragments_Handler.init(<settings>)` before calling `Cart_Fragments_Handler.instance()`.');
		}
		return Cart_Fragments_Handler._instance;
	}

	/**
	 * Initialises the class and its event handlers.
	 *
	 * @param settings ICart_Fragments_Handler_Settings
	 * @return Cart_Fragments_Handler
	 */
	public static init(settings: ICart_Fragments_Handler_Settings): Cart_Fragments_Handler {
		// Initialise the Edit_Product handler. We can't call Edit_Product.instance here,
		// as the handler must be initialised with the settings first
		return new Cart_Fragments_Handler(settings);
	}

	/**
	 * Removes an item from the browsers' session storage.
	 *
	 * @param item_key
	 */
	public remove_session_storage_item(item_key: string): void {
		try {
			// Invalidate cache of WooCommerce minicart when the currency is selected via
			// the URL. This will ensure that the minicart is updated correctly
			const supports_html5_storage = ((window['sessionStorage'] ?? null) !== null);
			if(supports_html5_storage) {
				// The fragment name might be generated dynamically by WooCommerce, so
				// we have to retrieve it from the WC parameters
				// @since WC 3.1
				sessionStorage.removeItem(item_key);
			}
		}
		catch(error) {
			const error_msg = 'Aelia - Exception occurred while accessing window.sessionStorage. ' +
												'This could be caused by the browser disabling cookies. ' +
												'COOKIES MUST BE ENABLED for the site to work correctly. ' +
												'Exception details below.';
			console.error(error_msg);
			console.error(error);
		}
	}

	/**
	 * Purges the cart fragments from the browser's storage session.
	 */
	public purge_cart_fragments(): void {
		const cart_fragments_name: string = (this.settings.cart_fragments_params['fragment_name'] ?? '').trim();
		if(cart_fragments_name) {
			this.remove_session_storage_item(cart_fragments_name);
		}
	}

	/**
	 * Invalidates the minicart cache when the currency has been selected via the URL.
	 */
	protected maybe_invalidate_minicart_cache(): void {
		const current_url: URL = new URL(window.location.href);
		const is_currency_set_by_url: boolean = ((current_url.searchParams.get(Cart_Fragments_Handler.ARG_CURRENCY) ?? '').length > 0);

		// If the currency is set via the URL, and there are cart fragments stored in the session,
		// remove them so that they can be fetched using the active currency
		if(is_currency_set_by_url) {
			this.purge_cart_fragments();
		}
	}
}